import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStatisticsSectionContext } from "../../Context/StatisticsSectionContext.jsx";
import { t } from "i18next";
import { getCurrentWeekNumber } from "../../Context/helper-functions.js";
import { MAX_WEEK, MIN_WEEK, CURRENT_YEAR } from "../../utils/constants.js";
import Error from "../Common/Error.jsx";

const FuelFilterForm = () => {
  const {
    apiData,
    paramsState,
    setParamsState,
    setSearchParams,
    setSearchParamsFunction,
    filterFuels,
    error,
  } = useStatisticsSectionContext();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      year: paramsState.year,
      start: paramsState.start,
      end: paramsState.end,
      fuels: paramsState?.fuels?.join(","),
    },
  });
  const [maxWeek, setMaxWeek] = useState(MAX_WEEK); // Default to MAX_WEEK

  useEffect(() => {
    const currentYear = watch("year");
    const calculatedMaxWeek =
      currentYear.toString() === CURRENT_YEAR.toString()
        ? getCurrentWeekNumber()
        : MAX_WEEK;

    setMaxWeek(calculatedMaxWeek);
  }, [watch("year")]);

  const onSubmit = (formData) => {
    // Swap weeks if start > end
    if (formData.start > formData.end) {
      [formData.start, formData.end] = [formData.end, formData.start];
    }

    // Ensure fuels is an array
    const fuels =
      typeof formData.fuels === "string"
        ? formData.fuels.split(",").map((fuel) => parseInt(fuel, 10))
        : Array.isArray(formData.fuels)
        ? formData.fuels.map((fuel) => parseInt(fuel, 10))
        : [];

    // Sanitize formData values
    const updatedParams = {
      ...paramsState,
      year: parseInt(formData.year, 10) || CURRENT_YEAR, // Fallback to current year
      start: parseInt(formData.start, 10) || MIN_WEEK, // Fallback to MIN_WEEK
      end: parseInt(formData.end, 10) || maxWeek, // Fallback to maxWeek
      fuels: fuels.filter((fuel) => !isNaN(fuel)), // Filter out NaN fuels
    };

    if (!!(updatedParams.year && updatedParams.start && updatedParams.end)) {
      filterFuels(updatedParams, apiData);
      setParamsState(updatedParams);
      setSearchParams(setSearchParamsFunction);
    }
  };

  return (
    <div className="container mx-auto flex">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="overflow-auto min-w-full max-h-full group cursor-default select-none py-2 text-gray-900 bg-neutral-50"
      >
        {/* Checkbox list */}
        <div className="w-full rounded py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {apiData.map((fuel, index) => (
            <label
              key={fuel.name}
              className="flex justify-between hover:bg-neutral-200 ml-3 block truncate font-normal flex-row-reverse items-center space-x-3"
            >
              <input
                type="checkbox"
                {...register("fuels")}
                value={index}
                defaultChecked={paramsState.fuels.includes(index)}
                className="flex h-4 w-4"
              />
              <span className="flex font-lg font-bold truncate ...">
                {index + 1}. {fuel.name}
              </span>
            </label>
          ))}
        </div>

        {/* Year input */}
        <div className="flex m-4 justify-stretch">
          <label className="block text-gray-700 font-bold p-2">
            {t("common.selectYear")}
          </label>
          <input
            type="number"
            min="2000"
            max={CURRENT_YEAR}
            {...register("year", { required: true })}
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500 min-w-[120px]"
          />
        </div>

        {/* Week interval input */}
        <div className="flex m-4 justify-stretch">
          <label className="block text-gray-700 font-bold p-2">
            {t("common.week")}
          </label>
          <div className="flex">
            <input
              id="start-week"
              type="number"
              min={MIN_WEEK}
              max={maxWeek}
              placeholder={paramsState.start}
              {...register("start", { required: true })}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400 min-w-fit"
            />
          </div>
          <div className="flex">
            <label htmlFor="end-week" className="flex text-sm font-medium p-2">
              {t("filterData.until")}
            </label>
            <input
              id="end-week"
              type="number"
              min={MIN_WEEK}
              max={maxWeek}
              placeholder={paramsState.end}
              {...register("end", { required: true })}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400 min-w-fit"
            />
          </div>
          <div className="flex">
            <button
              type="submit"
              className="rounded bg-[#297A49] text-white p-2 font-bold flex-end"
              label="Set filters"
            >
              {t("common.show")}
            </button>
          </div>
        </div>
        {error ? <Error messageKey={t("common.notFound")} /> : ""}
      </form>
    </div>
  );
};

export default FuelFilterForm;
