import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useStatisticsSectionContext } from "../../Context/StatisticsSectionContext";
import { useTranslation } from "react-i18next";

import { CURRENCY } from "../../utils/constants.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const colors = [
  {
    borderColor: "rgba(255, 99, 132, 1)",
    backgroundColor: "rgba(255, 99, 132, 0.8)",
  }, // Red
  {
    borderColor: "rgba(54, 162, 235, 1)",
    backgroundColor: "rgba(54, 162, 235, 0.8)",
  }, // Blue
  {
    borderColor: "rgba(75, 192, 192, 1)",
    backgroundColor: "rgba(75, 192, 192, 0.8)",
  }, // Teal
  {
    borderColor: "rgba(255, 206, 86, 1)",
    backgroundColor: "rgba(255, 206, 86, 0.8)",
  }, // Yellow
  {
    borderColor: "rgba(153, 102, 255, 1)",
    backgroundColor: "rgba(153, 102, 255, 0.8)",
  }, // Purple
  {
    borderColor: "rgba(255, 159, 64, 1)",
    backgroundColor: "rgba(255, 159, 64, 0.8)",
  }, // Orange
  {
    borderColor: "rgba(99, 255, 132, 1)",
    backgroundColor: "rgba(99, 255, 132, 0.8)",
  }, // Light Green
  {
    borderColor: "rgba(201, 203, 207, 1)",
    backgroundColor: "rgba(201, 203, 207, 0.8)",
  }, // Grey
  {
    borderColor: "rgba(255, 105, 180, 1)",
    backgroundColor: "rgba(255, 105, 180, 0.8)",
  }, // Hot Pink
  {
    borderColor: "rgba(255, 165, 0, 1)",
    backgroundColor: "rgba(255, 165, 0, 0.8)",
  }, // Gold
  {
    borderColor: "rgba(0, 255, 127, 1)",
    backgroundColor: "rgba(0, 255, 127, 0.8)",
  }, // Spring Green
];

export default function PriceDevelopmentGraph() {
  const { data } = useStatisticsSectionContext();
  const { t } = useTranslation();

  const processDate = (date) => {
    return date.split("(").at(0);
  };

  const graphData = useMemo(() => {
    return {
      labels: data[0]?.measuresArray?.map((el) => processDate(el.week)),
      datasets: data?.map((fuel, index) => ({
        label: fuel.name,
        data: fuel.measuresArray?.map((el) => el.value),
        borderColor: colors[index].borderColor,
        backgroundColor: colors[index].backgroundColor,
      })),
    };
  }, [data]);



  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${t("common.price")}: ${tooltipItem.raw}${CURRENCY}`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t("common.week"),
        },
      },
      y: {
        title: {
          display: true,
          text: t("common.price"),
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container max-h-full max-w-full min-h-full min-w-full p-10">
      <Line data={graphData} options={options} />
    </div>
  );
}
