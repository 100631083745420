import React from "react";
import { useTranslation } from "react-i18next";
import CustomList from "../Common/CustomList";

export default function SupportUs() {
  const { t } = useTranslation();
  const content = [
    {
      header: t("supportUs.supportUsHeader"),
      text: t("supportUs.supportUsText"),
    },
  ];

  return (
    <>
      <title>
        Podporte nás | Pomôžte zlepšiť údaje o palivách na Slovensku
      </title>
      <meta
        name="description"
        content="Prispievajte na Štatistiky palív na Slovensku a pomôžte nám zlepšiť pokrytie údajov. Vaša podpora umožňuje presné prehľady."
      />

      <CustomList content={content} />
      
      {/* Support us Section */}
      <div className="support-widgets">
        <h2 className="text-center text-xl font-bold my-6">
          {t("supportUs.widgetsHeader")} ❤️
        </h2>
        
        <div className="flex flex-wrap justify-center gap-8 mb-8">
          {/* Patreon Widget */}
          <a 
            href="https://www.patreon.com/Palivoslovensko" 
            target="_blank" 
            rel="noopener noreferrer"
            className="widget-link hover:scale-105 transition-transform"
          >
            <img 
              src="https://c5.patreon.com/external/logo/become_a_patron_button@2x.png" 
              alt="Podporte nás na Patreone" 
              className="h-12 w-auto"
            />
          </a>

          {/* Buy Me a Coffee Widget */}
          <a
            href="https://www.buymeacoffee.com/palivoslovt"
            target="_blank"
            rel="noopener noreferrer"
            className="widget-link hover:scale-105 transition-transform"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Kúpte nám kávu"
              className="h-12 w-auto"
            />
          </a>
        </div>

        <p className="text-center text-gray-600 text-sm max-w-2xl mx-auto">
          {t("supportUs.widgetsText")}
        </p>
      </div>
    </>
  );
}